define("discourse/plugins/discourse-subscriptions/discourse/components/modal/admin-cancel-subscription", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/helper", "discourse/components/d-button", "discourse/components/d-modal", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _helper, _dButton, _dModal, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AdminCancelSubscription;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AdminCancelSubscription = _exports.default = (_class = (_AdminCancelSubscription = class AdminCancelSubscription extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "refund", _descriptor, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @title={{i18n
          "discourse_subscriptions.user.subscriptions.operations.destroy.confirm"
        }}
        @closeModal={{@closeModal}}
      >
        <:body>
          <Input @type="checkbox" @checked={{this.refund}} />
          {{i18n "discourse_subscriptions.admin.ask_refund"}}
        </:body>
        <:footer>
          <DButton
            @label="yes_value"
            @action={{fn
              @model.cancelSubscription
              (hash
                subscription=@model.subscription
                refund=this.refund
                closeModal=@closeModal
              )
            }}
            @icon="times"
            @isLoading={{@model.subscription.loading}}
            class="btn-danger"
          />
          <DButton @label="no_value" @action={{@closeModal}} />
        </:footer>
      </DModal>
    
  */
  {
    "id": "ZuO816X/",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_subscriptions.user.subscriptions.operations.destroy.confirm\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[8,[32,2],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"refund\"]]]],null],[1,\"\\n        \"],[1,[28,[32,1],[\"discourse_subscriptions.admin.ask_refund\"],null]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-danger\"]],[[\"@label\",\"@action\",\"@icon\",\"@isLoading\"],[\"yes_value\",[28,[32,4],[[30,2,[\"cancelSubscription\"]],[28,[32,5],null,[[\"subscription\",\"refund\",\"closeModal\"],[[30,2,[\"subscription\"]],[30,0,[\"refund\"]],[30,1]]]]],null],\"times\",[30,2,[\"subscription\",\"loading\"]]]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@label\",\"@action\"],[\"no_value\",[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-subscriptions/discourse/components/modal/admin-cancel-subscription.js",
    "scope": () => [_dModal.default, _i18n.default, _component2.Input, _dButton.default, _helper.fn, _helper.hash],
    "isStrictMode": true
  }), _AdminCancelSubscription), _AdminCancelSubscription), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "refund", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});